///////////////////////////////////////////////
///////////// mixins
///////////////////////////////////////////////

@mixin counter {
  counter-reset: list;
  list-style: none;

  li:before {
    counter-increment: list;
    content: counter(list)'';
  }

}

@mixin more-400 {
  @media (min-width: 400px) {
    @content;
  }
}

@mixin more-576 {
  @media (min-width: 576px) {
    @content;
  }
}

@mixin more-768 {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin more-992 {
  @media (min-width: 992px) {
    @content;
  }
}

@mixin more-1200 {
  @media (min-width: 1200px) {
    @content;
  }
}

@mixin shadow {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, .15);
}

@mixin sameHeight {
  display: flex;
  flex-direction: row;

  > div:not([class]) {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
  }
}