/*********************************************
*********** offer
**********************************************/

.offer {
  background: #f0f0f0;
  width: calc(1005 + 30px);
  padding: 30px 40px;
  margin: 0 -15px;
  @include shadow;

  .offer-title {
    font-size: 20px;
    line-height: 22px;
    text-align: left;
    margin: 0 0 15px;
  }

  .btn-cost {
    margin: 20px 0 0;

    &:not(:disabled):not(.disabled) {
      cursor: default;
    }

    &:hover {
      background: #f06021;
    }

    &:active {
      box-shadow: navajowhite;
    }
  }

  @include more-576 {
    width: 100%;
    margin: 0;
  }

  @include more-1200 {
    box-shadow: none;
  }

}

@include more-768 {

  .main-footer-grid {

    .offer {
      position: relative;
      padding: 0;
      overflow: auto;

      &-inner {
        width: 100%;
        padding: 30px 30px 40px;
        position: absolute;
        left: 0;
      }

    }

  }

  .main-footer:not(.main-footer-grid) {

    .offer {
      height: 195px;
      overflow: auto;
    }

  }

}