/*********************************************
*********** catalog
**********************************************/

.catalog {
  width: calc(100% + 30px);
  padding: 20px 15px;
  background: #fff;
  margin: 0 -15px 20px;
  box-shadow: 0 -5px 5px 0 rgba(0, 0, 0, .1), 0 5px 5px 0 rgba(0, 0, 0, .1);

  &-menu {
    display: block;
    list-style: none;
    font-size: 16px;
    line-height: 1.3;

    &-title {
      font-size: 19px;
      line-height: 1;
      font-weight: 700;
      color: #094b6d;
      cursor: pointer;
    }

    &-item {
      padding: 5px 0;
      text-align: center;
    }

    @include more-1200 {

      &-title {

        &:not(a) {
          margin: 0 0 0 18px;
        }

        &:not(a):before {
          content: '';
          display: block;
          width: 8px;
          height: 5px;
          background: url(../images/icon-arrow-small-orange.png);
          position: absolute;
          top: 23px;
          left: 0;
          z-index: 1;
          transform-origin: center center;
          transition: .3s;
        }

        &:hover {
          color: #000;
        }

      }

      &-item {
        padding: 15px 0;
        border-bottom: 1px solid #cdcece;
        text-align: left;
        position: relative;

        &:last-of-type {
          border-bottom: navajowhite;
        }

        &.active {

          .catalog-menu-title {
            color: #000;

            &:before {
              background: url(../images/icon-arrow-small-orange-blue.png);
              transform: rotate(-180deg);
            }

          }

        }

      }

    }

  }

  &-submenu {
    display: none;
    list-style: none;

    &-item {
      padding: 0 10px 0 28px;
      margin: 0;

      @include more-1200 {
        margin: 10px 0 0;
      }

    }

    &-link {
      font-size: 16px;
      font-weight: 700;
      color: #414649;

      &:hover {
        color: #414649;
      }

    }

  }

  &-mobile {

    & + .catalog-menu {
      display: none;
    }

    &-select {
      width: 100%;
      list-style: none;
      background: linear-gradient(to bottom, #fff, #fbfbfb);
      line-height: 40px;
      font-size: 14px;
      color: #414649;
      padding: 0 44px 0 25px;
      border: 1px solid #cfd3d4;
      border-radius: 20px;
      position: relative;
      background: #f9fafa;

      &:after {
        content: '';
        display: block;
        width: 8px;
        height: 5px;
        background: url(../images/icon-arrow-small-orange.png);
        position: absolute;
        top: 18px;
        right: 18px;
        z-index: 1;
        transform-origin: center center;
        transition: .3s;
      }

      &-value {
        display: block;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &-menu {
        display: none;
        width: calc(100% + 19px);
        margin: 0;
        padding: 0 0 15px;
        list-style: none;
      }

      &-item {
        padding: 10px;
        border-bottom: 1px solid #cdcece;
        font-size: 12px;
        line-height: 1.1;
        font-weight: 700;

        &:last-of-type {
          border-bottom: none;
        }

        &:hover,
        &.active {
          color: #f06021;
        }

      }

      &.active {

        &:after {
          transform: rotate(-180deg);
        }

      }

      @include more-768 {
        width: 280px;
        flex-shrink: 0;
      }

    }

    &-links {
      padding: 10px 0 0;
      list-style: none;

      .catalog-submenu-item {
        padding: 5px 25px;
        font-size: 16px;
        line-height: 1.2;

        a {
          font-weight: 700;
          color: #414649;

          &:hover {
            color: #414649;
            text-decoration: underline;
          }

        }

      }

      @include more-768 {
        padding: 0;
        width: 320px;
      }

    }

    @include more-768 {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    @include more-1200 {
      display: none;

      & + .catalog-menu {
        display: block;
      }

    }

  }

  @include more-768 {
    width: 100%;
    margin: 25px 0;
  }

  @include more-1200 {
    padding: 10px 20px 10px 25px;
    margin: 0;
    box-shadow: none;
  }

}