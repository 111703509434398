/*********************************************
*********** store
**********************************************/

.store {

  &-select {

    &-main {
      width: 250px;
      margin: 0 auto;
    }

    &-data {
      width: 210px;
      margin: 0 auto;
    }

    &-amount {
      width: 190px;
      margin: 20px auto;
    }

  }

  &-list {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
    margin: 20px 0 0;

    &-header {
      display: none;

      &.active {
        display: flex;
        margin: 20px 20px -20px;

        .store-list-header-item {
          padding: 20px 0;
          background: #f0f0f0;

          &:nth-of-type(1) {
            width: 18%;
            padding: 20px 0 20px 20px;
            flex-shrink: 0;
          }

          &:nth-of-type(2) {
            width: calc(47% + 20px);
            padding: 20px 0 20px 120px;
            flex-shrink: 0;
          }

          &:nth-of-type(3) {
            flex-grow: 1;
            flex-shrink: 0;
          }

          &:nth-of-type(4) {
            width: 125px;
            flex-shrink: 0;
          }

        }

        @include more-1200 {
          margin: 20px 0 -20px;
        }

      }

    }

    @include more-768 {
      padding: 0 20px;
      justify-content: flex-start;

      &.store-list-grid {

        .store-card {
          width: calc(33.33333% - 30px);
          margin: 15px 45px 15px 0;

          &:nth-of-type(3n) {
            margin: 15px 0;
          }

        }

      }

      &.store-list-lines {
        display: block;

        .store-card {
          display: flex;
          width: 100%;
          @include shadow;
          margin: 20px 0;

          &:nth-of-type(3n) {
            margin: 20px 0;
          }

          &-header {
            width: 170px;
            height: auto;
            flex-shrink: 0;
          }

          &-data,
          &-footer {
            display: flex;
            justify-content: center;
            flex-direction: column;
            padding: 20px;
          }

          &-data {
            flex-grow: 1;
          }

          &-footer {
            width: 155px;
            flex-shrink: 0;
          }

        }

      }

      &.store-list-table {
        display: block;

        .store-card {
          display: flex;
          width: 100%;
          @include shadow;
          margin: 0;
          border-bottom: 1px solid #cdcece;
          background: #fff;
          box-shadow: none;

          &:nth-of-type(3n) {
            margin: 0;
          }

          &-header {
            width: 18%;
            height: auto;
            flex-shrink: 0;
          }

          &-data {
            display: flex;
            align-items: center;
            width: 47%;
            flex-shrink: 0;
            padding: 20px 0;
          }

          &-footer {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 35%;
            padding: 20px;
            flex-shrink: 0;
          }

          &-title {
            width: 120px;
            flex-shrink: 0;
            margin: 0;
          }

          &-cost {
            margin: 0 10px 0 0;
            font-size: 18px;
            line-height: 1.2;
          }

        }

      }

    }

    @include more-1200 {
      padding: 0;

      &.store-list-grid {

        .store-card {
          width: calc(25% - 15px);
          margin: 12px 20px 12px 0;

          &:nth-of-type(3n) {
            margin: 12px 20px 12px 0;
          }

          &:nth-of-type(4n) {
            margin: 12px 0;
          }

        }

      }

      &.store-list-lines {

        .store-card {

          &:nth-of-type(4n) {
            margin: 20px 0;
          }

          &-header {
            width: 200px;
          }

          &-footer {
            flex-direction: row;
            align-items: center;
            width: 265px;
          }

          &-cost {
            line-height: 1.2;
            margin: 0 20px 0 0;
          }

        }

      }

    }

  }

  &-card {
    width: 200px;
    margin: 15px auto;
    @include shadow;
    background: #f0f0f0;

    &-header {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px 20px;
      height: 150px;
      background: #fff;
    }

    &-data {
      padding: 15px 25px;
    }

    &-title {
      font-size: 17px;
      line-height: 1;
      font-weight: 700;
      text-transform: uppercase;
      color: #094b6d;
    }

    &-text {
      font-size: 15px;
      line-height: 20px;
    }

    &-footer {
      padding: 10px 20px 15px;
      background: #fff;
      text-align: center;
    }

    &-cost {
      font-size: 20px;
      margin: 0 0 10px;
    }

  }

  &-header {

    @include more-768 {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

  }

  &-sort {

    @include more-768 {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      .is-select {
        margin: 0 20px 0 0;
      }

    }

  }

  &-view {

    @include more-768 {
      display: flex;

      & > div {
        width: 40px;
        height: 40px;
        background-color: #fff;
        background-position: center center;
        background-repeat: no-repeat;
        border: 1px solid #d1d4d5;
        border-radius: 50%;
        margin: 0 0 0 7px;

        &.active {
          background-color: #f06021;
          border: 1px solid #f06021;
        }

      }

      &-grid {
        background-image: url(../images/icon-view-grid-black.png);

        &.active {
          background-image: url(../images/icon-view-grid-white.png);
        }

      }

      &-lines {
        background-image: url(../images/icon-view-lines-black.png);

        &.active {
          background-image: url(../images/icon-view-lines-white.png);
        }

      }

      &-table {
        background-image: url(../images/icon-view-table-black.png);

        &.active {
          background-image: url(../images/icon-view-table-white.png);
        }

      }

    }

  }

}

.content-header .store-title {
  margin: 0 0 15px;
}