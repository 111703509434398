/*********************************************
*********** card
**********************************************/

.card {
  flex-grow: 1;

  &-header {
    display: block;
    width: 100%;
    padding: 100% 0 0;
    position: relative;
    color: #fff;
    overflow: hidden;
    border: none;

    > img {
      min-height: 100%;
      min-width: 100%;
      max-width: none;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
    }

    &-video {

      &:after {
        content: '';
        display: flex;
        justify-content: center;
        align-items: center;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        box-shadow: 0 0 0 20px rgba(0, 0, 0, .57);
        background: rgba(0, 0, 0, .57) url(../images/icon-video.png) no-repeat center center;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        z-index: 3;
        transition: .4s;
      }

      &:hover {

        &:after {
          box-shadow: 0 0 0 10px rgba(0, 0, 0, .75);
          background: rgba(0, 0, 0, .75) url(../images/icon-video.png) no-repeat center center;
        }

      }

    }

    &-full {
      padding: 60% 0 0;
    }

  }

  &-label {
    padding: 3px 10px 5px;
    border-radius: 10px;
    background: #f55e1f;
    font-size: 12px;
    line-height: 1;
    font-weight: 700;
    text-transform: uppercase;
    position: absolute;
    top: 30px;
    left: 30px;
    z-index: 2;
  }

  &-content {
    padding: 10px 25px;
  }

  &-title {
    font-size: 20px;
    line-height: 25px;
    margin: 0;

    a {
      color: #000;

      &:hover {
        color: #000;
        text-decoration: underline;
      }

    }

  }

  &-date {
    font-size: 14px;
    line-height: 25px;
  }

}