/*********************************************
*********** contacts
**********************************************/

.on-map {
  @include shadow;
  margin: 25px 0 0;

  &-wrapper {
    border: 10px solid #fff;

    iframe {
      width: 100%;
      height: 200px;
      border: none;
    }

  }

  @include more-768 {
    display: flex;

    &-wrapper {
      flex-grow: 1;

      iframe {
        height: 100%;
      }

    }

    .contacts {
      width: 300px;
      flex-shrink: 0;
    }

  }

}

.contacts {
  padding: 15px 20px 25px;
  font-size: 15px;
  line-height: 18px;
  color: #000;

  h2 {
    margin: 0 0 5px;
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    color: #000;
    text-transform: uppercase;
    text-align: left;
  }

  h3 {
    margin: 10px 0 5px;
    font-weight: 700;
    font-size: 17px;
    line-height: 1;
    color: #094b6d;
  }

  &-item {
    padding: 0 0 0 35px;
    position: relative;
    margin: 5px 0 15px;
  }

  &-icon {
    position: absolute;
    left: 0;
    top: 0;
  }

  &-note {
    margin: 0 0 20px;
    color: #5d6364;

    a {
      color: #094b6d;
      text-decoration: underline;
      font-weight: 700;

      &:hover {
        text-decoration: none;
        color: #094b6d;
      }

    }

  }

  address {
    margin: 5px 0;
  }

  p {
    font-size: 15px;
    line-height: 18px;
    margin: 5px 0;
  }

  &-phone {
    margin: 5px 0;

    a {
      color: #000;
      margin: 0 10px 0 0;

      &:hover {
        color: #000;
      }

    }

  }

  &-email {

    a {
      color: #000;
      text-decoration: underline;

      &:hover {
        color: #000;
        text-decoration: none;
      }

    }

  }

  .social-links {
    padding: 0;
    justify-content: flex-start;
  }

}

.form-callback {
  max-width: 520px;
  margin: 0 0 30px;

  &-title {
    margin: 25px 0 0;
  }

  .form-row {
    margin: 20px 0;
    justify-content: center;

    @include more-768 {
      justify-content: space-between;
    }

  }

}