/*********************************************
*********** header
**********************************************/

.header {
  flex-shrink: 0;

  &-top {
    width: 100%;
    background: #414649;
    padding: 10px 0;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 100;

    .col-12 {
      position: static;
    }

    &-left {
      display: flex;
      align-items: center;
      flex-shrink: 0;
    }

    &-right {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex-shrink: 0;
      flex-grow: 1;

      .burger-search {
        display: none;
        margin: 0 0 0 10px;
      }

    }

    @include more-1200 {

      &-right {
        position: relative;

        .burger-search {
          display: none;
        }

        .btn-order {
          display: none;
        }

      }

      &.short {

        .header-top-right {

          .burger-search {
            display: block;
            cursor: pointer;
          }

          .btn-order {
            display: flex;
            padding: 5px 30px 6px;
          }

          .header-search-form {
            position: absolute;
            right: 30px;
            width: 0;

            label:not(.label-checkbox) {

              input {
                width: 0;
                padding: 0;
              }

            }

            button[type="submit"] {
              width: 0;
            }

            &.active {
              width: 100%;

              label:not(.label-checkbox) {

                input {
                  width: 100%;
                  padding: 0 50px 0 20px;
                }

              }

              button[type="submit"] {
                width: 44px;
              }

            }

          }

        }

      }

    }

  }

  &-search {

    &-mobile {
      width: calc(100% - 20px);
      padding: 15px;
      background: #156894;
      box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0);
      transition: .6s;
      position: absolute;
      left: -100%;
      top: 100%;

      &:after {
        content: '';
        display: block;
        width: 0;
        height: 0;
        border-bottom: 7px solid #156894;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        position: absolute;
        left: 48px;
        bottom: 100%;

        @include more-576 {
          left: calc(50% - 211px);
        }

        @include more-768 {
          left: calc(50% - 301px);
        }

        @include more-992 {
          left: calc(50% - 421px);
        }

      }

      &.active {
        left: 0;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, .5);
      }

      @include more-1200 {
        display: none;
      }

    }

    &-form {
      position: relative;
      width: 100%;
      transition: .4s;

      label:not(.label-checkbox) {

        input {
          display: block;
          width: 100%;
          font-size: 14px;
          border: none;
          height: 30px;
          padding: 0 50px 0 20px;
          border-radius: 15px;
        }

      }

      button[type="submit"] {
        display: block;
        width: 44px;
        height: 30px;
        border-radius: 15px;
        border: none;
        background: #fff;
        background-position: center center;
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;
        transition: none;

        &:hover {
          background: rgba(0, 0, 0, .1);
          box-shadow: inset 0 0 10px 0 rgba(0, 0, 0, .25);
        }

        &:active {
          background: rgba(0, 0, 0, .25);
          box-shadow: inset 0 0 10px 0 rgba(0, 0, 0, .5);
        }

      }

      @include more-1200 {
        max-width: 450px;
      }

    }

  }

  &-nav {
    width: calc(100% - 20px);
    background: #156894;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0);
    transition: .6s;
    position: absolute;
    left: -100%;
    top: 100%;

    &:after {
      content: '';
      display: block;
      width: 0;
      height: 0;
      border-bottom: 7px solid #156894;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      position: absolute;
      left: 16px;
      bottom: 100%;

      @include more-576 {
        left: calc(50% - 245px);
      }

      @include more-768 {
        left: calc(50% - 335px);
      }

      @include more-992 {
        left: calc(50% - 455px);
      }

    }

    &.active {
      left: 0;
      box-shadow: 0 0 15px 0 rgba(0, 0, 0, .5);
    }

    .block {

      &-address {
        padding: 20px 15px;
        background: #094b6d;
      }

    }

    @include more-1200 {
      position: static;
      background: none;
      box-shadow: none;
    }

  }

  &-menu {
    padding: 20px 24px 15px;
    font-size: 17px;
    list-style: none;
    margin: 0;

    &-item {
      padding: 0 0 10px;
    }

    &-link {
      color: #fff;
      flex-shrink: 0;

      &:hover {
        color: #fff;
      }

    }

    a:hover {
      text-decoration: underline;
    }

    &-arrow {
      display: none;
      flex-shrink: 0;
    }

    @include more-1200 {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 0;

      &-item {
        display: flex;
        align-items: center;
        padding: 0;
        margin: 0 20px 0 0;
        position: relative;
        flex-shrink: 0;

        &.with-submenu:after {
          content: '';
          display: none;
          width: 0;
          height: 0;
          border-bottom: 5px solid #e7e9e9;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          position: absolute;
          bottom: 0;
          right: -1px;
        }

        &:last-of-type {
          margin: 0;
        }

      }

      &-arrow {
        display: block;
        width: 8px;
        height: 5px;
        background: url(../images/icon-arrow-small-orange.png);
        margin: 5px 0 0 5px;
        transition: .3s;
      }

    }

  }

  &-submenu {
    list-style: none;
    padding: 0 0 0 10px;

    &-link {
      color: #5dd1ff;

      &:hover {
        color: #5dd1ff;
      }

    }

    @include more-1200 {
      display: none;
      width: 205px;
      background: #e7e9e9;
      box-shadow: 0 5px 10px 0 rgba(0, 0, 0, .25);
      position: absolute;
      left: 0;
      top: 100%;
      padding: 0;

      &-link {
        font-size: 15px;
        font-weight: 700;
        color: #000;

        &:hover {
          color: #000;
        }

      }

      &-item {
        border-bottom: 1px solid #afb2b4;
        border-top: 1px solid #fff;
        padding: 7px 20px 7px 30px;
        position: relative;

        &:before {
          content: '';
          display: block;
          width: 8px;
          height: 5px;
          background: url(../images/icon-arrow-small-orange.png);
          transform: rotate(-90deg);
          position: absolute;
          left: 15px;
          top: 17px;
        }

        &:first-of-type {
          border-top: none;
        }

        &:last-of-type {
          border-bottom: none;
        }

      }

    }

  }

  &-info {
    padding: 15px 0;
    background: #094b6d;
    border-top: 2px solid #185675;
    border-bottom: 2px solid #185675;

    &-inner {
      display: flex;
      align-items: flex-start;

      .block-phone {

        &-item {
          padding: 10px 0;
        }

        @include more-768 {
          flex-shrink: 0;
          margin: 0 60px 0 30px;
        }

        @include more-1200 {
          order: 3;
          margin: 0 50px;
        }

      }

      @include more-400 {
        justify-content: center;
      }

      @include more-768 {
        justify-content: space-between;
        align-items: center;
      }

    }

    @include more-1200 {
      background-color: #094b6d;
      background-image: url(../images/background-header-info-left.png), url(../images/background-header-info-right.png);
      background-position: left top, right top;
      background-repeat: no-repeat, no-repeat;
      background-size: auto 100%, auto 100%;
    }

  }

  &-logo {
    flex-shrink: 0;
    max-width: 90px;
    margin: 0 20px 0 0;

    @include more-1200 {
      opacity: 1;
      max-width: 140px;
      margin: 0 100px 0 0;
    }

  }

  &-catalog {
    width: 280px;
    margin: 0 auto;

    &-control {
      display: none;
    }

    &-wrap {
      padding: 10px 0;
      background: #156894;

      @include more-768 {
        position: relative;
      }

    }

    &-item {
      display: none;
      justify-content: flex-start;
      align-items: center;
      padding: 10px 0;

      &:first-of-type {
        display: block;
      }

      @include more-768 {
        display: flex !important;
        padding: 0 25px 0 0;
      }

    }

    &-icon {
      display: inline-block;
      vertical-align: middle;
      flex-shrink: 0;
      max-width: 40px;
      margin: 0 15px 0 0;
    }

    &-link {
      display: inline-block;
      vertical-align: middle;
      font-size: 20px;
      font-weight: 700;
      color: #fff;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
        color: #fff;
      }

    }

    &-arrow {
      display: inline-block;
      vertical-align: middle;
      margin: 7px 0 0 15px;
      transition: .4s;

      &.active {
        transform: rotate(-180deg);
      }

      @include more-768 {
        display: none;
      }

    }

    @include more-768 {
      width: 100%;
      margin: 0;
      padding: 0 25px;

      &-wrap {

        &:before,
        &:after {
          content: '';
          display: block;
          width: calc(50% - 300px);
          position: absolute;
          top: 0;
          bottom: 0;
          z-index: 1;
        }

        &:before {
          background: linear-gradient(to left, transparent, #156894 25px);
          left: -1px;
        }

        &:after {
          background: linear-gradient(to right, transparent, #156894 25px);
          right: -1px;
        }

      }

      .slick-arrow {
        width: 16px;
        height: 11px;
        background: url(../images/icon-arrow-medium-white.png);
        font-size: 0;
        color: transparent;
        border: none;
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        z-index: 2;

        &.slick-prev {
          left: calc(50% - 350px);
          right: auto;
          transform: rotate(90deg);

          &:hover {
            background: url(../images/icon-arrow-medium-white.png);
          }

          &:active {
            transform: rotate(90deg);
          }

        }

        &.slick-next {
          display: block;
          left: auto;
          right: calc(50% - 350px);
          transform: rotate(-90deg);

          &:hover {
            background: url(../images/icon-arrow-medium-white.png);
          }

          &:active {
            transform: rotate(-90deg);
          }

        }

      }

    }

    @include more-992 {

      &-wrap {

        &:before,
        &:after {
          width: calc(50% - 420px);
        }

      }

      &:before {
        background: linear-gradient(to left, transparent, #156894 65%);
      }

      &:after {
        background: linear-gradient(to right, transparent, #156894 65%);
      }

      .slick-arrow {

        &.slick-prev {
          left: calc(50% - 470px);
        }

        &.slick-next {
          right: calc(50% - 470px);
        }

      }

    }

    @include more-1200 {
      display: flex;
      justify-content: center;

      &-wrap {
        position: absolute;
        left: 0;
        top: 100%;
        width: 100%;
        background: rgba(14, 110, 160, .87);
        z-index: 1;

        &:after,
        &:before {
          display: none;
        }

      }

      &-item {
        padding: 0 30px 0 0;
      }

      &-item:last-of-type {
        padding: 0;
      }

    }

  }

  &-email {
    display: none;

    .btn-order {
      display: none;
    }

    @include more-1200 {
      display: block;
      text-align: center;
      flex-shrink: 0;
      order: 4;
      line-height: 1;

      .btn-order {
        display: flex;
      }

      &-title {
        margin: 10px 0 5px;
        font-size: 17px;
        font-weight: 700;
        color: #fff;
      }

      &-link {
        font-size: 17px;
        color: #5dd1ff;
        text-decoration: underline;

        &:hover {
          text-decoration: none;
          color: #5dd1ff;
        }

      }

    }

  }

  @include more-1200 {
    position: relative;
  }

  .btn {
    padding: 6px 20px 8px;
    font-size: 14px;
    background-position: center -1px;
    background-size: 90% auto;

    &-inner-icon {
      display: none;
    }

    @include more-1200 {
      font-size: 17px;
      padding: 6px 30px 8px;
      background-position: center -7px;
      background-size: 95% auto;

      &-inner-icon {
        display: block;
        flex-shrink: 0;
        margin: 0 5px 0 0;
      }

    }

  }

  form {

    label {
      margin: 0;

      &:not(.label-checkbox) {
        display: flex;
        justify-content: flex-end;
        width: 100%;

        input {

        }

      }

    }

  }

}

.block {

  &-address {
    font-size: 15px;
    color: #fff;

    &-item {
      position: relative;
      padding: 0 0 0 38px;
      margin: 0 0 15px;

      &:last-of-type {
        margin: 0;
      }

    }

    &-icon {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      max-width: 25px;
      position: absolute;
      left: 0;
      top: 0;
    }

    &-title {
      margin: 0 0 3px;
      text-transform: uppercase;
      color: #5dd1ff;
    }

    @include more-768 {
      line-height: 1.1;
    }

    @include more-1200 {
      line-height: 1.3;
    }

  }

  &-phone {
    width: 155px;

    &-item {
      display: none;
      position: relative;

      &:first-of-type {
        display: block;
      }

      @include more-1200 {
        display: block !important;
      }

    }

    &-link {
      font-size: 18px;
      color: #fff;

      &:hover {
        color: #fff;
        text-decoration: underline;
      }

    }

    &-text {
      font-size: 14px;
      color: #5dd1ff;
      line-height: 1;
    }

    &-arrow {
      position: absolute;
      top: 19px;
      right: -25px;
      transition: .4s;

      &.active {
        transform: rotate(-180deg);
      }

      @include more-1200 {
        display: none;
      }

    }

    @include more-1200 {
      width: auto;
      flex-shrink: 0;

      &-link {
        font-size: 22px;
      }

      &-text {
        font-size: 15px;
        line-height: 1.2;
      }

    }

  }

}

.burger {
  display: block;
  width: 22px;
  height: 18px;
  position: relative;
  cursor: pointer;
  margin: 0 10px 0 0;
  transition: .4s;

  > div {
    height: 2px;
    background: #e1e5e8;
    position: absolute;
    left: 0;
    transition: .4s;
    transform-origin: left center;
  }

  &-top {
    width: 100%;
    top: 0;
  }

  &-center {
    width: 14px;
    margin: auto;
    top: 0;
    bottom: 0;
  }

  &-bottom {
    width: 19px;
    bottom: 0;
  }

  &.active {

    .burger-top {
      transform: rotate(45deg);
    }

    .burger-center {
      left: 50%;
      width: 0;
    }

    .burger-bottom {
      width: 100%;
      bottom: .5px;
      transform: rotate(-45deg);
    }
  }

  &.hidden {
    transform: translateY(-100px);
  }

  @include more-1200 {
    display: none;
  }

  &-search {
    width: 20px;
    height: 20px;
    position: relative;
    transition: .4s;

    &:before {
      content: '';
      display: block;
      width: 16px;
      height: 16px;
      border: 2px solid #eff0f0;
      border-radius: 50%;
      transform-origin: left center;
      position: absolute;
      top: 0;
      left: 4px;
      transition: .4s;
    }

    &:after {
      content: '';
      display: block;
      width: 10px;
      height: 2px;
      background: #eff0f0;
      position: absolute;
      bottom: 0;
      left: 0;
      transform-origin: left center;
      transform: rotate(-45deg);
      transition: .4s;
    }

    &.active {

      &:before {
        width: 25px;
        height: 0;
        border: 1px solid #eff0f0;
        border-radius: 0;
        transform: rotate(45deg);
        left: 0;
      }

      &:after {
        width: 25px;
      }

    }

    &.hidden {
      transform: translateY(-100px);
    }

    @include more-1200 {
      display: none;
    }

  }

}

.header-menu-item:hover {

  .header-menu-arrow {
    transform: rotate(-180deg);
  }

  &:after {
    display: block;
  }

  .header-submenu {
    display: block;
  }

}

