/*********************************************
*********** partners
**********************************************/

.partners {
  text-align: center;

  @include more-768 {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px 0 0;
  }

  @include more-1200 {
    align-items: flex-end;
  }

  &-list {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    text-align: left;
    margin: 25px 0;

    @include more-768 {
      justify-content: flex-start;
      margin: 0;
    }

  }

  &-item {
    margin: 0 20px;

    &:nth-of-type(2) ~ .partners-item {
      display: none;

      @include more-768 {
        display: block;
      }

    }

  }

  &-logo {
    height: 25px;

    img {
      height: 100%;
    }

  }

  &-title {
    font-size: 17px;
    line-height: 1;
    color: #6b8086;
    margin: 10px 0 0;
  }

}