/*********************************************
*********** footer
**********************************************/

.footer {
  flex-shrink: 0;
  padding: 25px 0 10px;
  background: #202020 url(../images/background-footer-mobile.png) no-repeat 0 0;
  text-align: center;
  font-size: 17px;
  color: #fff;

  &-inner {

    @include more-768 {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-wrap: wrap;
    }

  }

  &-copyrights {

    span {
      display: block;
    }

    @include more-768 {

      span {
        display: inline;
        vertical-align: middle;
        margin: 0 10px 0 0;
      }

    }

  }

  &-tagline {
    margin: 10px 0 0;
  }

  &-privacy-policy {
    margin: 10px 0 0;

    a {
      color: #5dd1ff;

      &:hover {
        text-decoration: underline;
        color: #5dd1ff;
      }

    }

  }

  &-catalog {
    display: none;

    @include more-768 {
      display: block;
      max-width: 200px;
      padding: 0 0 0 20px;

      a {
        display: block;
        font-size: 17px;
        color: #5dd1ff;
        position: relative;
        margin: 0 0 10px;

        &:hover {
          color: #5dd1ff;
          text-decoration: underline;
        }

        &:before {
          content: '';
          display: block;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background: #e86122;
          position: absolute;
          top: 0;
          bottom: 0;
          left: -20px;
          margin: auto;
        }

      }

    }

  }

  .block-phone {
    display: none;

    @include more-768 {
      align-self: center;
      display: flex;
      justify-content: flex-end;
      flex-grow: 1;

      &-item {

        &:first-of-type {
          margin: 0 25px 0 0;
        }

      }

    }

    @include more-1200 {
      align-self: flex-start;

      &-link {
        font-size: 22px;
      }

    }

  }

  .block-address {
    display: none;

    @include more-1200 {
      display: block;
      position: absolute;
      bottom: 55px;
      right: 0;
    }

  }

  @include more-768 {
    text-align: left;

    br {
      display: none;
    }

    .footer-info {
      width: calc(100% - 250px);
    }

  }

  @include more-1200 {

    .footer-inner {
      position: relative;
    }

    .footer-info {
      width: 450px;
    }

    .social-links {
      justify-content: flex-start;
    }

  }

}

.social-links {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 0;

  a {
    max-width: 40px;
    flex-shrink: 0;
    margin: 5px;

    &:hover {
      transform: translateY(-5px);
    }

  }

}