/*********************************************
*********** filter
**********************************************/

.filter {
  width: calc(100% + 30px);
  margin: 25px -15px;
  padding: 15px;
  background: #f0f0f0;
  @include shadow;

  &-form {
    display: none;

    @include more-1200 {
      display: block;
    }

  }

  &-show {
    display: flex;
    justify-content: center;
    align-items: center;

    &-icon {
      margin: 0 10px 0 0;
    }

    &-text {
      font-size: 17px;
      line-height: 1;
      font-weight: 700;
    }

    @include more-1200 {
      display: none;
    }

  }

  &-title {
    margin: 0 0 15px;
    text-align: center;
    font-size: 14px;
    line-height: 18px;
    font-weight: 700;
    color: #176b96;

    @include more-768 {
      text-align: left;
    }

  }

  &-item {
    border-bottom: 1px solid #cdcece;
    padding: 20px 0;
    position: relative;

    .is-tooltip {
      position: absolute;
      top: 20px;
      right: 0;
    }

  }

  &-values {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 25px 0 0;
    position: relative;

    .label-field {
      margin: 0;

      input {
        width: 120px;
        height: 40px;
        line-height: 40px;
        border: 1px solid #cfd3d4;
        border-radius: 20px;
        text-align: center;
        font-size: 14px;

        @include more-1200 {
          width: 90px;
        }

      }

    }

    &:after {
      content: '';
      display: block;
      width: 15px;
      height: 1px;
      background: #000;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      margin: auto;
    }

  }

  .noUi-target.noUi-horizontal {
    background: #c1c9cb;
    height: 6px;
    border-radius: 3px;
    border: none;
    box-shadow: none;

    .noUi-handle {
      width: 16px;
      height: 15px;
      border-radius: 50%;
      background: #ef6024;
      box-shadow: 0 0 0 6px #f0f0f0;
      border: none;
      right: -7px;
      left: auto;

      &:before,
      &:after {
        display: none;
      }

    }

    .noUi-connect {
      background: #ef6024;
    }

  }

  .custom-checkbox input[type=checkbox] + label {
    align-items: center;
  }

  .btn {
    width: 100%;
    margin: 20px 0;
  }

  @include more-768 {
    width: 100%;
    margin: 25px 0;
    padding: 20px 30px;
  }

  @include more-1200 {
    margin: 0 0 50px;
    padding: 0 15px;
    box-shadow: none;
  }

}