/*********************************************
*********** article
**********************************************/

.article-single {

  h1,
  h2,
  h3 {
    clear: both;
    margin: 0 0 10px;
    text-align: left;
    color: #176b96;
    font-weight: 700;
  }

  h2 {
    font-size: 28px;
    line-height: 30px;
  }

  h3 {
    font-size: 24px;
    line-height: 25px;
  }

  &-marker {
    display: inline-block;
    vertical-align: middle;
    padding: 0 10px;
    border-radius: 12px;
    background: #f55e1f;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 700;
    color: #fff;
    line-height: 25px;
    margin: 0 20px 0 0;
  }

  &-header {

    time {
      font-size: 14px;
      line-height: 25px;
      color: #8b999d;
    }

  }

  p {
    margin: 15px 0;
    font-size: 17px;
    line-height: 30px;
  }

  &-image {
    @include shadow;
    display: inline-block;
    border: 10px solid #fff;
    margin: 15px 0;
    max-width: 100%;

    @include more-576 {
      margin: 0 25px 25px 0;
      float: left;
      max-width: 50%;
    }

  }

  ul,
  ol {
    margin: 15px 0;
    list-style: none;

    li {
      font-size: 17px;
      line-height: 30px;
    }

  }

  ul li {
    padding: 0 0 0 35px;
    position: relative;

    &:before {
      content: '';
      display: block;
      width: 4px;
      height: 4px;
      background: #176b96;
      border-radius: 50%;
      position: absolute;
      left: 15px;
      top: 13px;
    }

  }

  ol {
    @include counter;

    li:before {
      content: counter(list) '.';
      font-size: 15px;
      color: #0e608a;
      font-weight: 300;
      margin: 0 10px;
    }

  }

  &-share {
    margin: 20px 0;

    img {
      margin: 0;
    }

  }

}

.comments {
  margin: 0 0 30px;

  &-item {
    padding: 0 0 15px;
    margin: 0 0 15px;
    border-bottom: 1px solid #a3a8aa;
    box-shadow: 0 1px 0 0 #fff;
  }

  h2 {
    margin: 15px 0;
  }

  h3 {
    font-size: 24px;
    line-height: 25px;
    margin: 0 0 10px;
    text-align: left;
    color: #176b96;
    font-weight: 700;
  }

  &-name {
    font-size: 19px;
    line-height: 25px;
    color: #094b6d;
    font-weight: 700;
  }

  &-text {
    margin: 5px 0;
    font-size: 15px;
    line-height: 23px;
  }

  time {
    font-size: 14px;
    line-height: 1;
    color: #8b999d;
  }

  &-form {
    width: 100%;
    max-width: 440px;
  }

}