/*********************************************
*********** list
**********************************************/

.list {

  @include more-768 {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
  }

  &-item {

    @include more-768 {
      width: calc(50% - 15px);
      margin: 0 30px 15px 0;

      &:nth-of-type(2n) {
        margin: 0 0 15px;
      }

    }

  }

  &-subtitle {

    a {
      font-size: 20px;
      line-height: 32px;
      color: #f06021;
      text-decoration: underline;

      &:hover {
        color: #f06021;
        text-decoration: none;
      }

    }

  }

}