//////////////////////////////////////////////
//////// fonts
//////////////////////////////////////////////
@import url('https://fonts.googleapis.com/css?family=PT+Sans:400,700&subset=cyrillic');

//////////////////////////////////////////////
//////// libs
//////////////////////////////////////////////
@import "../../node_modules/nouislider/distribute/nouislider.css";
@import "../../node_modules/bootstrap/scss/bootstrap";
@import "../../node_modules/slick-carousel/slick/slick.scss";
@import "../../node_modules/@fancyapps/fancybox/dist/jquery.fancybox.css";

//////////////////////////////////////////////
//////// tools
//////////////////////////////////////////////
@import "tools/variables";
@import "tools/mixins";

//////////////////////////////////////////////
//////// blocks
//////////////////////////////////////////////
@import "general";
@import "blocks/header";
@import "blocks/footer";
@import "blocks/slider";
@import "blocks/breadcrumbs";
@import "blocks/catalog";
@import "blocks/chief";
@import "blocks/offer";
@import "blocks/steps";
@import "blocks/product";
@import "blocks/store";
@import "blocks/filter";
@import "blocks/contacts";
@import "blocks/gallery";
@import "blocks/article";

//////////////////////////////////////////////
//////// components
//////////////////////////////////////////////
@import "components/buttons";
@import "components/card";
@import "components/partners";
@import "components/news";
@import "components/brands";
@import "components/list";