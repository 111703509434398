/*********************************************
*********** brands
**********************************************/

.brands {
  text-align: center;

  &-list {

    @include more-768 {
      display: flex;
      flex-wrap: wrap;
    }

  }

  &-item {
    width: 100%;
    max-width: 290px;
    background: #fff;
    @include shadow;
    margin: 0 auto 20px;

    @include more-768 {
      width: calc(33.33333% - 30px);
      max-width: none;
      margin: 0 45px 30px 0;
      flex-shrink: 0;

      &:nth-of-type(3n) {
        margin: 0 0 30px;
      }

    }

    @include more-1200 {
      width: calc(25% - 15px);
      margin: 0 20px 30px 0;

      &:nth-of-type(3n) {
        margin: 0 20px 30px 0;
      }

      &:nth-of-type(4n) {
        margin: 0 0 30px;
      }

    }

  }

  &-image {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 130px;
    height: 100px;
    margin: 0 auto;
    padding: 10px 0;

    img {
      width: auto;
      max-height: 100%;
    }

  }

  &-name {
    padding: 0 20px 30px;
    text-align: center;
    font-size: 17px;
    line-height: 22px;
  }

  @include more-1200 {
    text-align: left;
  }

}