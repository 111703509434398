/*********************************************
*********** slider
**********************************************/

.slider {

  &-main {
    margin: 0 0 25px;

    &-slide {
      padding: 45px 0;
      text-align: center;
      color: #fff;

      .col-12 {
        background: rgba(0, 0, 0, .57);
        padding: 45px 15px;
      }

      @include more-1200 {
        padding: 128px 0 70px;

        .container {
          padding: 0 30px;
        }

        .col-12 {
          padding: 30px 220px 65px;
        }

      }

    }

    &-title {
      font-size: 28px;
      line-height: 1.2;
      font-weight: 700;
      color: inherit;
      margin: 0 0 15px;
      text-align: center;

      @include more-768 {
        font-size: 35px;
      }

      @include more-1200 {
        color: #5dd1ff;
      }

    }

    &-text {
      display: block;
      font-size: 17px;
      line-height: 24px;
    }

    .slick-arrow {
      top: 60px;
      background: url(../images/icon-arrow-white.png);

      @include more-1200 {
        top: 220px;

        &.slick-prev {
          left: calc(50% - 500px);
          right: auto;
        }

        &.slick-next {
          right: calc(50% - 500px);
          left: auto;
        }

      }

    }

    .slick-dots {
      position: absolute;
      left: 50%;
      bottom: 60px;
      transform: translateX(-50%);

      li {
        background: none;
      }

      @include more-1200 {
        bottom: 90px;
      }

    }

  }

  &-list {
    width: 310px;
    padding: 20px 0;
    margin: 10px -10px;

    .slick-list {
      overflow: visible;
    }

    .slick-slide {
      margin: 0 15px;
      @include shadow;
    }

    &-item {
      display: inline-flex!important;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      padding: 20px;
      position: relative;
      background: #fff;
      font-size: 17px;
      line-height: 20px;
    }

    &-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 20px 0 0;
      flex-shrink: 0;
      width: 68px;
      height: 74px;
      background: url(../images/icon-list-background.png);
      transform: translateY(6px);

      img {
        margin: 0 0 10px;
      }

    }

    @include more-768 {
      width: 100%;
      margin: 0;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, .15);
      background: #fff;

      &-item {
        padding: 0 35px 10px 45px;
      }

    }

  }

  &-portfolio {
    width: 260px;
    margin: 30px auto 0;
    padding: 20px 0 0;

    &-title {

      @include more-768 {
        text-align: center;
      }

      @include more-1200 {
        text-align: left;
      }

    }

    .slick-slide {
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, .15);
      @include sameHeight;
      @include shadow;
    }

    @include more-768 {
      width: 500px;

      .slick-slide {
        margin: 0 15px;
      }

    }

    @include more-1200 {
      width: 100%;
      padding: 0;
      margin: 0 -15px;
    }

  }

  &-reviews {
    padding: 23px;
    background: #fff;
    margin: 40px 0 0;
    @include shadow;

    @include more-1200 {
      margin: 0;

      &-wrap {
        padding-top: 0;
      }

    }

    &-title {
      text-align: center;
      margin: 0 0 15px;

      @include more-1200 {
        text-align: left;
      }

    }

    &-slide {
      text-align: center;

      &-title {
        font-size: 20px;
        line-height: 25px;
        margin: 10px 0 0;
      }

    }

    .slick-arrow {
      top: -30px;

      @include more-1200 {
        top: -37px;
      }

    }

  }

  &-clients {
    width: calc(100% + 40px);
    margin: 20px -20px 0;
    padding: 0;

    @include more-1200 {
      margin: 0 -20px;
    }

    &-title {
      margin: 0 0 15px;

      @include more-768 {
        text-align: center;
      }

      @include more-1200 {
        text-align: left;
      }

    }

    &-wrap {
      overflow: hidden;
    }

    &-slide {
      display: inline-flex!important;
      justify-content: center;
      align-items: center;
    }

    .slick-slide {
      @include sameHeight;
      margin: 10px 20px;

      @include more-768 {
        position: relative;

        &:after {
          content: '';
          display: block;
          width: 0;
          height: 100%;
          border-left: 1px solid #9ba8ab;
          border-right: 1px solid #fff;
          border-radius: 50%;
          position: absolute;
          top: 0;
          right: calc(100% + 21px);
        }

      }

    }

    .slick-arrow {

      &.slick-prev {

        @include more-1200 {
          right: 121px;
        }

      }

      &.slick-next {

        @include more-1200 {
          right: 30px;
        }

      }

    }

  }

}

.slick-arrow {
  display: block;
  width: 66px;
  height: 18px;
  background: url(../images/icon-arrow-black.png);
  box-shadow: none;
  border: none;
  position: absolute;
  top: -15px;
  font-size: 0;
  color: transparent;
  z-index: 1;
  cursor: pointer;

  &.slick-prev {
    right: calc(50% + 10px);

    @include more-1200 {
      right: 106px;
    }

  }

  &.slick-next {
    left: calc(50% + 10px);
    transform: rotate(180deg);

    @include more-1200 {
      left: auto;
      right: 15px;
    }

  }

  &:hover {
    background: url(../images/icon-arrow-orange.png);
  }

  &:active {

    &.slick-prev {
      transform: translateX(-5px);
    }

    &.slick-next {
      transform: translateX(5px) rotate(180deg);
    }

  }

  @include more-1200 {
    top: -40px;
  }

}

.slick-dots {
  display: flex;
  justify-content: center;
  align-items: stretch;
  list-style: none;
  margin: 0;
  line-height: 1;
  font-size: 0;

  li {
    padding: 12px 3px;
    background: rgba(0, 0, 0, .4);
    line-height: 1;

    &:first-child {
      padding: 12px 3px 12px 15px;
      border-radius: 15px 0 0 15px;
    }

    &:last-child {
      padding: 12px 15px 12px 3px;
      border-radius: 0 15px 15px 0;
    }

    button {
      width: 25px;
      height: 6px;
      background: #fff;
      border: none;
      font-size: 0;
      color: transparent;
      line-height: 1;
      cursor: pointer;
    }

    &.slick-active button {
      background: #f55e1f;
    }

  }

}

.portfolio {
  width: 260px;
  margin: 0 auto;

  .card {
    flex-shrink: 0;
    flex-grow: initial;
  }

  .card-header {
    padding-top: 100%;
  }

  .slick-slide {
    @include sameHeight;
  }

  .slick-arrow {
    top: -30px;
  }

  @include more-768 {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 100%;
    margin: 0;
  }

}

.portfolio-title {
  margin: 0 0 40px;

  @include more-768 {
    text-align: center;
    margin: 0;
  }

  @include more-1200 {
    text-align: left;
  }

}

.portfolio-3 {

  @include more-768 {

    .card {
      width: calc(33.33333% - 20px);
      margin: 30px 30px 0 0;

      &:nth-of-type(3n) {
        margin: 30px 0 0;
      }

    }

  }

}

.portfolio-4 {

  @include more-768 {

    .card {
      width: calc(33.33333% - 20px);
      margin: 30px 30px 0 0;

      &:nth-of-type(3n) {
        margin: 30px 0 0;
      }

    }

  }

  @include more-1200 {

    .card {
      width: calc(25% - 15px);
      margin: 20px 20px 0 0;

      &-title {
        font-size: 15px;
        line-height: 20px;
      }

      &:nth-of-type(3n) {
        margin: 20px 20px 0 0;
      }

      &:nth-of-type(4n) {
        margin: 20px 0 0;
      }

    }

  }

}