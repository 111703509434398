/*********************************************
*********** breadcrumbs
**********************************************/

.breadcrumbs {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 7px 0 18px;
  font-size: 15px;
  line-height: 1;
  list-style: none;
  margin: 0;

  li {
    margin: 10px 20px 0 0;
    flex-shrink: 0;

    &:last-child {
      margin: 10px 0 0;
    }

    a {
      color: #6a7276;
      position: relative;

      &:hover {
        color: #6a7276;
        text-decoration: underline;
      }

      &:after {
        content: '';
        display: block;
        width: 5px;
        height: 8px;
        background: url(../images/icon-arrow-small-blue.png);
        position: absolute;
        left: calc(100% + 8px);
        bottom: 5px;
      }

    }

  }

}