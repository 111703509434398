/*********************************************
*********** gallery
**********************************************/

.gallery {

  &-header {
    margin: 0 0 40px;
    text-align: center;

    &-item {
      font-size: 14px;
      font-weight: 700;
      padding: 5px;
      border-bottom: 1px solid #d2d9db;
      color: #094b6d;

      &:last-of-type {
        margin: 0 0 10px;
        border-bottom:  none;
      }

      &.active {
        color: #ee631a;

        a {
          color: #ee631a;

          &:hover {
            color: #ee631a;
          }

        }

      }

      a {
        color: #094b6d;

        &:hover {
          text-decoration: none;
        }

      }

      @include more-768 {
        text-transform: uppercase;
        font-size: 15px;
        color: #094b6d;
        margin: 0;
        padding: 0 10px;
        border: none;
        position: relative;

        &:last-of-type {
          margin: 0;
        }

        &.active {

          a {
            color: #094b6d;

            &:hover {
              color: #094b6d;
            }

          }

          &:after {
            content: '';
            display: block;
            width: 100%;
            height: 3px;
            background: #ee631a;
            position: absolute;
            left: 0;
            bottom: -8px;
          }

        }

        a {

          &:hover {
            color: #ee631a;
          }

        }

      }

    }

    @include more-768 {
      display: flex;
      padding: 5px 0;
      margin: 0 0 10px;
      border-bottom: 3px solid #b3b5b6;
      text-align: left;
    }

  }

  .portfolio-4 {

    @include more-1200 {

      .card {
        width: calc(25% - 42px);
        margin: 20px 56px 20px 0;

        &-title {
          font-size: 20px;
          line-height: 22px;
        }

        &:nth-of-type(3n) {
          margin: 20px 56px 20px 0;
        }

        &:nth-of-type(4n) {
          margin: 20px 0;
        }

      }

    }

  }

  .pagination {
    margin: 15px 0 25px;
    flex-wrap: wrap;
    justify-content: center;

    &-text {
      width: 100%;
      font-size: 17px;
      line-height: 1;
      font-weight: 700;
      text-align: center;
      margin: 15px 0;
    }

    @include more-768 {
      flex-wrap: nowrap;
      margin: 20px 0 30px;

      &-text {
        width: auto;
        margin: 0 20px 0 0;
      }

    }

  }

}

h1.gallery-item {
  margin: 0;

  @include more-768 {
    margin: 0 0 25px;
  }

}