/*********************************************
*********** steps
**********************************************/

.steps {
  margin: 60px 0 20px;

  .slick-slide {
    @include sameHeight;
  }

  .slick-arrow {
    top: -35px;
  }

  &-item {
    width: 20%;
    margin: 0 auto;
    text-align: center;
    position: relative;

    @include more-768 {
      text-align: left;
    }

    @include more-1200 {

      &:after {
        content: '';
        display: block;
        border-top: 1px solid #b6c8cd;
        border-bottom: 1px solid #fff;
        position: absolute;
        top: 35px;
        left: 80px;
        right: 20px;
      }

      &:last-of-type {

        &:after {
          display: none;
        }

      }

    }

  }

  &-number {
    font-size: 12px;
    line-height: 1;
    font-weight: 700;
    color: #939a9c;
    position: absolute;
    left: calc(50% + 30px);
    top: 0;

    @include more-768 {
      left: 80px;
    }

  }

  &-icon {
    display: block;
    width: 68px;
    height: 74px;
    margin: 0 auto;
    padding: 20px 0 0;
    background: url(../images/icon-list-background.png);

    @include more-768 {
      margin: 0;
    }

  }

  &-text {
    font-size: 15px;
    line-height: 20px;
    font-weight: 700;
  }

  @include more-768 {

    .slick-active {
      position: relative;

      &:before {
        content: '';
        display: block;
        border-top: 1px solid #b6c8cd;
        border-bottom: 1px solid #fff;
        position: absolute;
        top: 35px;
        right: calc(100% + 25px);
        width: calc(100% - 115px);
      }

    }

  }

  @include more-992 {
    margin: 70px 0 30px;

    .slick-active {

      &:before {
        right: calc(100% + 25px);
        width: calc(100% - 120px);
    }

    }

    .slick-arrow {
      top: -45px;
    }

  }

  @include more-1200 {
    display: flex;
    margin: 50px 0 30px;

    &-text {
      font-size: 13px;
      line-height: 18px;
      padding: 0 15px 0 0;
    }

  }

}