/*********************************************
*********** news
**********************************************/

.news {
  text-align: center;

  @include more-1200 {
    text-align: left;
  }

  &-title {
    margin: 0;

    @include more-768 {
      display: inline-block;
      vertical-align: middle;
    }

    &-link {
      display: inline-block;
      color: #000;
      font-size: 30px;
      line-height: 32px;
      font-weight: 700;
      position: relative;
      margin: 10px 0 45px;

      &:after {
        content: '';
        display: block;
        width: 100%;
        height: 2px;
        background: linear-gradient(to right, #000 6px, transparent 6px) repeat-x;
        background-size: 10px 2px;
        position: absolute;
        left: 0;
        top: 100%;
      }

      &:hover {
        text-decoration: none;
        color: #000;

        &:after {
          display: none;
        }

      }

      @include more-768 {
        display: inline-block;
        vertical-align: middle;
        margin: 0 0 0 20px;
      }

    }

  }

  &-list {
    margin: 10px 0 0;

    @include more-768 {
      display: flex;
      margin: 25px 0 15px;
    }

    @include more-1200 {
      margin: 30px 0 0;

      & + .btn {
        position: absolute;
        top: 0;
        right: 20px;
      }

    }

  }

  &-item {

    &-header {
      width: 100px;
      height: 100px;
      border: 5px solid #fff;
      position: relative;
      @include shadow;
      margin: 0 auto;
      flex-shrink: 0;

      img {
        min-width: 100%;
        min-height: 100%;
        max-width: none;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
      }

      @include more-1200 {
        margin: 0 35px 0 0;
      }

    }

    &-date {
      padding: 0 15px;
      border-radius: 12px;
      background: #f55e1f;
      font-size: 12px;
      font-weight: 700;
      color: #fff;
      line-height: 25px;
      position: absolute;
      top: 20px;
      left: 20px;
      z-index: 2;
    }

    &-title {
      font-size: 20px;
      line-height: 23px;
      margin: 15px 0;
      font-weight: 700;

      a {
        color: #e9641c;

        &:hover {
          color: #e9641c;
          text-decoration: underline;
        }

      }

      @include more-1200 {
        margin: 0;
      }

    }

    &-text {
      font-size: 17px;
      line-height: 23px;
      margin: 15px 0;
    }

    @include more-768 {
      width: 50%;
      padding: 0 25px;
    }

    @include more-1200 {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 0 20px 0 0;
    }

  }

  .slick-arrow {
    top: -35px;
  }

  &-page {
    margin: 10px 0 0;

    .news-item {
      text-align: center;
      margin: 40px 0 25px;

      &:first-of-type {
        margin: 25px 0;
      }

      &-mark {
        padding: 0 10px;
        border-radius: 12px;
        background: #f55e1f;
        font-size: 12px;
        text-transform: uppercase;
        font-weight: 700;
        color: #fff;
        line-height: 25px;
        position: absolute;
        top: 20px;
        left: 20px;
        z-index: 2;
      }

      time {
        color: #8b999d;
        font-size: 14px;
      }

      &-header {
        width: 190px;
        height: 150px;
        overflow: hidden;
        border: 10px solid #fff;

        @include more-1200 {
          margin: 0 35px 0 0;
        }

      }

      &-title {
        margin: 10px 0;
        font-size: 17px;
        line-height: 1.2;

        a {
          text-decoration: underline;
          color: #176b96;

          &:hover {
            text-decoration: none;
            color: #176b96;
          }

        }

        @include more-768 {
          margin: 0 0 10px;
        }

      }

      &-text {
        margin: 5px 0;
      }

      @include more-768 {
        display: flex;
        align-items: flex-start;
        text-align: left;
        width: 100%;
        padding: 0;

        &-header {
          margin: 0 30px 0 0;
        }

      }

      @include more-1200 {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 0 20px 0 0;
      }

    }

    .pagination {
      justify-content: center;
      flex-wrap: wrap;

      @include more-768 {
        justify-content: flex-start;
        flex-wrap: nowrap;
      }

      &-text {
        width: 100%;
        text-align: center;
        margin: 0 0 20px 0;

        @include more-768 {
          width: auto;
          text-align: left;
          margin: 0 20px 0 0;
        }

      }

      &-numbers li {
        margin: 0 5px;

        a {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 30px;
          height: 30px;
          background: #fff;
          border: 1px solid #d4d4d4;
          border-radius: 50%;
          font-size: 17px;
          line-height: 1;

          @include more-768 {
            width: 40px;
            height: 40px;
          }

          &:hover {
            background: lightgrey;
            text-decoration: none;
          }

          &.active {
            background: #094c6e;
            color: #fff;

            &:hover {
              background: #094c6e;
            }

          }

        }

      }

      @include more-768 {
        justify-content: flex-start;
      }

    }

  }

}