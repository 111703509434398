/*********************************************
*********** buttons
**********************************************/

.btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  border: none;
  padding: 11px 30px 12px;
  border-radius: 20px;
  font-size: 17px;
  line-height: 1em;
  color: #fff;
  background: url(../images/background-button.png) no-repeat center calc(100% - 16px);
  background-size: 95% auto;
  transition: .4s;
  cursor: pointer;

  &-wrapper {
    text-align: center;

    @include more-768 {
      text-align: left;
    }

  }

  &-cost {
    background: #f06021;
    padding: 11px 20px 12px;

    .btn-inner-text {
      color: #fff;
    }

    &:hover {
      background-color: #cd6021;
      color: #fff;
    }

  }

  &-inner-icon {
    display: block;
    flex-shrink: 0;
    margin: 0 5px 0 0;
  }

  &:hover {
    background-position: center 40px;
  }

  &:active {
    box-shadow: inset 0 0 10px 1px rgba(0, 0, 0, .4);
  }

  &-orange {
    background-color: #f06021;

    &:hover {
      background-color: #cd6021;
      color: #fff;
    }

  }

  &-blue {
    background-color: #0d75aa;

    &:hover {
      background-color: #0d5287;
      color: #fff;
    }

  }

  &-dark-blue {
    background: #094b6d url(../images/background-dark-blue-button.png) no-repeat center calc(100% - 16px);
    background-size: 95% auto;
  }

}
