/*********************************************
*********** product
**********************************************/

.product {

  &-card {
    @include shadow;
    margin: 20px 0 0;

    @include more-768 {
      display: flex;
    }

  }

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include more-768 {
      display: inline-block;
      text-align: center;
    }

    @include more-1200 {
      margin: 25px 0 35px;
    }

  }

  &-cost {
    font-size: 20px;
    color: #000;
  }

  &-logo {
    display: none;

    @include more-768 {
      display: block;
      max-width: 120px;
      position: absolute;
      top: 30px;
      right: 10px;
    }

    @include more-1200 {
      top: 55px;
    }

  }

  &-slider {
    background: #fff;
    padding: 0 0 20px;
    overflow: hidden;

    &-main {
      padding: 35px 30px;

      @include more-768 {
        flex-grow: 1;
      }

    }

    &-small {
      width: calc(100% - 52px);
      margin: 0 auto;

      .slick-arrow {
        width: 23px;
        height: 100%;
        background: #bdc4c6;

        &:hover {
          background: #094b6d;
        }

        &:after {
          content: '';
          display: block;
          width: 16px;
          height: 11px;
          background: url(../images/icon-arrow-medium-white.png) no-repeat center center;
          margin: auto;
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          right: 0;
          z-index: 1;
        }

        &.slick-prev {
          top: 0;
          bottom: 0;
          left: -26px;
          right: auto;

          &:after {
            transform: rotate(90deg);
          }

          &:active {
            transform: none;
          }

        }

        &.slick-next {
          top: 0;
          bottom: 0;
          right: -26px;
          left: auto;

          &:after {
            transform: rotate(90deg);
          }

          &:active {
            transform: rotate(180deg);
          }

        }

      }

      .slick-slide {
        border: 2px solid #bdc4c6;
        margin: 0 3px;

        &.slick-current {
          border-color: #ee631a;
        }

      }

      @include more-768 {
        flex-shrink: 0;
      }

    }

    @include more-768 {
      display: flex;
      flex-direction: column;
      width: 420px;
      flex-shrink: 0;
    }

    @include more-1200 {
      width: 450px;
      flex-shrink: 0;
    }

  }

  &-data {
    background: #f0f0f0;
    padding: 20px;

    @include more-768 {
      flex-grow: 1;
      position: relative;
      padding: 15px 20px;
    }

  }

  &-properties {
    padding: 10px 10px 20px;
    font-size: 15px;
    border-bottom:  1px solid #cdcece;

    &-item {
      margin: 0 0 5px;

      @include more-1200 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 0 10px;
      }

    }

    &-name {
      font-weight: 700;
    }

    &-value {

      a {
        font-weight: 700;
        text-decoration: underline;
        color: #ee631a;

        &:hover {
          text-decoration: none;
          color: #ee631a;
        }

      }

    }

    .show-more {
      font-size: 15px;
      color: #094b6d;
      margin: 10px 0 0;

      &:after {
        height: 1px;
        background: linear-gradient(to right,#094b6d 4px,transparent 4px) repeat-x;
        background-size: 8px 1px;
      }

      &:hover {
        color: #094b6d;
      }

    }

    @include more-1200 {
      padding: 10px 10px 30px;
    }

  }

  &-footer {
    padding: 10px 0;
    border-top:  1px solid #fff;

    &-item {
      display: flex;
      align-items: center;
      margin: 10px 0 0;

      &:first-of-type {
        margin: 0;
      }

      @include more-1200 {
        flex-shrink: 0;
        margin: 0;
      }

    }

    &-link {
      font-size: 16px;
      font-weight: 700;
      color: #094b6d;

      &:hover {
        color: #094b6d;
        text-decoration: underline;
      }

      @include more-1200 {
        font-size: 15px;
      }

    }

    &-icon {
      margin: 0 10px 0 0;

      @include more-1200 {
        margin: 0 5px 0 0;
      }

    }

    @include more-1200 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 25px 0;
    }

  }

  &-tab {

    &-control {
      display: none;

      &-mobile {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 40px;
        margin: 15px 0 0;
        font-size: 15px;
        font-weight: 700;
        text-transform: uppercase;
        position: relative;

        &:after {
          content: '';
          display: block;
          width: 8px;
          height: 5px;
          background: url(../images/icon-arrow-small-orange.png);
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          margin: auto;
          transition: .4s;
        }

        &.active {

          &:after {
            transform: rotate(-180deg);
          }

        }

        @include more-768 {
          display: none;
        }

      }

      &-item {
        font-size: 14px;
        font-weight: 700;
        padding: 5px;
        border-bottom: 1px solid #d2d9db;
        cursor: pointer;

        &:last-of-type {
          margin: 0 0 10px;
          border-bottom:  none;
        }

        &.active {
          color: #ee631a;
          cursor: default;
        }

        @include more-768 {
          text-transform: uppercase;
          font-size: 15px;
          color: #094b6d;
          margin: 0;
          padding: 0 10px;
          border: none;
          position: relative;

          &:last-of-type {
            margin: 0;
          }

          &.active {
            color: #000;

            &:after {
              content: '';
              display: block;
              width: 100%;
              height: 3px;
              background: #ee631a;
              position: absolute;
              left: 0;
              bottom: -8px;
            }

          }

        }

      }

      @include more-768 {
        display: flex!important;
        padding: 5px 0;
        margin: 15px 0 0;
      }

    }

    &-content {
      width: calc(100% + 30px);
      margin: 0 -15px;
      padding: 15px;
      border-top: 3px solid #b3b5b6;

      &-item {
        display: none;
      }

      @include more-768 {
        width: 100%;
        margin: 0;
      }

      @include more-1200 {
        padding: 15px 0;
      }

    }

  }

  &-note {
    border: 1px solid #d2d9db;
    background: #ddd;
    padding: 20px;
    margin: 0 0 30px;
  }

}

.content-header .product-title {

  @include more-1200 {
    margin: 0;
  }

}