/*********************************************
*********** chief
**********************************************/

.chief {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;
  width: calc(100% + 30px);
  margin: 0 -15px;
  padding: 30px 20px 30px 40px;
  background: linear-gradient(to right, #1a77a2 50%, #176b96);

  &-photo {
    width: 66px;
    border-radius: 50%;
    overflow: hidden;
    @include shadow;
    flex-shrink: 0;
  }

  &-data {
    width: calc(100% - 85px);
  }

  &-position {
    font-size: 17px;
    line-height: 22px;
    color: #55e5ff;
  }

  &-name {
    font-size: 18px;
    line-height: 22px;
    font-weight: 700;
    color: #fff;
    margin: 10px 0 0;
  }

  &-contacts {
    width: 100%;
    margin: 5px 0 0;

    &-list {
      font-size: 17px;
      line-height: 30px;
      color: #fff;
      list-style: none;

      li {
        padding: 0 0 0 20px;
        position: relative;

        &:before {
          content: '';
          display: block;
          width: 5px;
          height: 5px;
          background: #39eaff;
          border-radius: 50%;
          position: absolute;
          left: 10px;
          top: 14px;
        }

      }

    }

    a {
      color: #fff;

      &:hover {
        color: #fff;
      }

      &.chief-contacts-phone {

        &:hover {
          text-decoration: underline;
        }

      }

      &.chief-contacts-email {
        text-decoration: underline;

        &:hover {
          text-decoration: navajowhite;
        }

      }

    }

  }

  @include more-1200 {
    width: 100%;
    margin: 0;
    justify-content: space-between;
    flex-wrap: wrap;
    box-shadow: none;

    &-data {
      flex-shrink: initial;
      width: calc(100% - 85px);
      margin: 0;
    }

    &-contacts {
      margin: 10px 0 0;
    }

  }

}

.main-footer:not(.main-footer-grid) {

  @include more-768 {
    justify-content: flex-start;
    flex-wrap: nowrap;

    &-data {
      flex-shrink: 0;
      width: auto;
      margin: 0 40px 0 20px;
    }

    &-contacts {
      margin: 0;
    }

  }

}