/*********************************************
*********** general
**********************************************/

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}

body {
  color: #000;
}

*::-moz-focus-inner {
  border: 0;
}

a,
button {
  transition: .4s;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
  margin: auto;
}

.slick-prev:hover,
.slick-next:hover,
.slick-prev:active,
.slick-next:active,
.slick-prev:focus,
.slick-next:focus,
.slick-slide:hover,
.slick-slide:active,
.slick-slide:focus,
a:hover,
a:active,
a:focus,
button:hover,
button:active,
button:focus,
.btn:hover,
.btn:active,
.btn:focus,
input:hover,
input:active,
input:focus,
textarea:hover,
textarea:active,
textarea:focus {
  outline: none;
  box-shadow: none;
}

.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh ;
  min-width: 320px;
  overflow: hidden;
  opacity: 0;
  position: relative;
  padding: 48px 0 0;

  @include more-1200 {
    padding: 50px 0 0;
  }

}

.content {
  flex-grow: 1;
  background: url(../images/background.jpg) repeat 0 0;
}

main {
  width: 100%;
  flex-grow: 1;
}

.modal-content {
  padding: 20px;

  @include more-1200 {
    padding: 25px 40px 40px;
  }

  .close {
    line-height: 1;
    position: absolute;
    top: 5px;
    right: 10px;
  }

}

.modal-dialog {

  @include more-1200 {
    max-width: 830px;
  }

}

.modal-header {
  margin: 10px 0;
  padding: 0;
  font-size: 27px;
  line-height: 32px;
  color: #176b96;
  font-weight: 700;
  border: none;

  @include more-1200 {
    margin: 0;
  }

}

ul {
  margin: 0;
}

.form {

  &-row {
    margin: 15px 0;

    &:last-of-type {
      margin: 15px 0 0;
    }

    @include more-1200 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 30px 0;
    }

    .label-field,
    .label-field-full {
      display: block;
      width: 100%;
      margin: 0 0 15px;

      @include more-1200 {
        width: calc(50% - 10px);
        margin: 0;
      }

      &:last-of-type {
        margin: 0;
      }

      input,
      textarea {
        display: block;
        width: 100%;
        height: 40px;
        line-height: 40px;
        padding: 0 15px;
        border: 1px solid #cfd3d4;
        border-radius: 20px;
      }

      textarea {
        height: 115px;
        resize: none;
        padding: 5px 15px;
      }

    }

    .label-field-full {

      @include more-1200 {
        width: 100%;
      }

    }

  }

  &-captcha {
    width: 100%;
    margin: 0 0 20px;

    &-question {
      font-size: 14px;
      line-height: 18px;
      margin: 0 0 10px;

      @include more-1200 {
        margin: 0 15px 0 0;
      }

    }

    .label-field {

      @include more-1200 {
        max-width: 100px;
      }

    }

    @include more-1200 {
      display: flex;
      align-items: center;
      width: calc(50% - 10px);
      margin: 0;
    }

  }

  &-upload {
    width: 100%;

    &-text {
      font-size: 14px;
      line-height: 18px;
      margin: 0 0 10px;

      @include more-1200 {
        flex-shrink: 0;
        margin: 0 15px 0 0;
      }

    }

    .label-upload {
      display: flex;
      width: 100%;
      height: 40px;
      line-height: 40px;
      padding: 0 0 0 15px;
      border: 1px solid #cfd3d4;
      border-radius: 20px;
      overflow: hidden;

      input {
        display: none;
      }

      &-file {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-grow: 1;
        font-size: 14px;
        line-height: 18px;
        font-weight: 700;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &-text {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-shrink: 0;
        padding: 0 15px 0 10px;
        background: url(../images/background-button-upload.png);
        background-size: 100% 100%;
        color: #fff;
      }

      @include more-1200 {
        margin: 0;
      }

    }

    @include more-1200 {
      display: flex;
      align-items: center;
      width: calc(50% - 10px);
    }

  }

}

.custom-checkbox {
  margin: 0 0 20px;

  @include more-1200 {
    width: calc(50% - 10px);
    margin: 0;
  }

  input[type='checkbox'] {
    display: none;
  }

  input[type='checkbox'] + label {
    display: flex;
    align-items: flex-start;

    &:before {
      content: '';
      display: block;
      width: 30px;
      height: 30px;
      border: 1px solid #cfd3d4;
      background: #f9fafa;
      border-radius: 3px;
      flex-shrink: 0;
      margin: 0 15px 0 0;
    }

    span {
      font-size: 14px;
      line-height: 18px;
    }

    @include more-1200 {
      align-items: center;
    }

  }

  input[type='checkbox']:checked + label {

    &:before {
      background: #f9fafa url(../images/icon-checkbox.png) no-repeat center center;
    }

  }

}

input::-webkit-input-placeholder {
  font-family: $font-regular;
  font-size: 14px;
  color: #79878b;
  opacity: 1;
}

input::-moz-placeholder {
  font-family: $font-regular;
  font-size: 14px;
  color: #79878b;
  opacity: 1;
}

input:-moz-placeholder {
  font-family: $font-regular;
  font-size: 14px;
  color: #79878b;
  opacity: 1;
}

input:-ms-input-placeholder {
  font-family: $font-regular;
  font-size: 14px;
  color: #79878b;
  opacity: 1;
}

textarea::-webkit-input-placeholder {
  font-family: $font-regular;
  font-size: 14px;
  color: #79878b;
  opacity: 1;
}

textarea::-moz-placeholder {
  font-family: $font-regular;
  font-size: 14px;
  color: #79878b;
  opacity: 1;
}

textarea:-moz-placeholder {
  font-family: $font-regular;
  font-size: 14px;
  color: #79878b;
  opacity: 1;
}

textarea:-ms-input-placeholder {
  font-family: $font-regular;
  font-size: 14px;
  color: #79878b;
  opacity: 1;
}

input:focus::-webkit-input-placeholder {
  color: transparent;
}

input:focus::-moz-placeholder {
  color: transparent;
}

input:focus:-moz-placeholder {
  color: transparent;
}

input:focus:-ms-input-placeholder {
  color: transparent;
}

textarea:focus::-webkit-input-placeholder {
  color: transparent;
}

textarea:focus::-moz-placeholder {
  color: transparent;
}

textarea:focus:-moz-placeholder {
  color: transparent;
}

textarea:focus:-ms-input-placeholder {
  color: transparent;
}

body.modal-open {
  padding: 0!important;
}

article.article {
  padding: 0 0 30px;

  .btn-wrapper {
    margin: 20px 0 0;
  }

  .article-image {
    padding: 10px 0;
    width: calc(100% + 30px);
    margin: 20px -15px;
    background: #fff;
    @include shadow;
    float: left;

    img {
      width: 100%;
    }

    @include more-576 {
      width: 100%;
      margin: 20px 0;
      padding: 10px;
    }

    @include more-768 {
      width: auto;
      margin: 0 20px 10px 0;
    }

  }

  @include more-1200 {
    padding: 0 0 50px;
  }

}

section {
  padding: 0 0 30px;
}

h2 {
  font-size: 30px;
  line-height: 36px;
  font-weight: 700;
  color: #176b96;
  text-align: center;
  margin: 0 0 25px;

  @include more-768 {
    text-align: left;
  }

  @include more-1200 {
    font-size: 32px;
    text-align: left;
  }

}

.inner-page {

  article.article {
    padding: 0 0 20px;
  }

  h1 {
    font-size: 30px;
    line-height: 36px;
    font-weight: 700;
    color: #176b96;
    text-align: center;
    margin: 0 0 25px;

    @include more-768 {
      text-align: left;
      margin: 0;
    }

    @include more-1200 {
      font-size: 32px;
      text-align: left;
    }

  }

  h2 {
    font-size: 26px;
    line-height: 30px;
  }

}

p {
  font-size: 17px;
  line-height: 30px;
}

a.show-more {
  display: inline-block;
  color: #000;
  font-size: 20px;
  line-height: 1;
  font-weight: 700;
  position: relative;
  margin: 10px 0 45px;

  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    background: linear-gradient(to right, #000 6px, transparent 6px) repeat-x;
    background-size: 10px 2px;
    position: absolute;
    left: 0;
    top: 100%;
  }

  &:hover {
    text-decoration: none;
    color: #000;

    &:after {
      display: none;
    }

  }

}

.custom-list {
  font-size: 17px;
  line-height: 27px;
  list-style: none;

  &-item {
    padding: 0 0 0 25px;
    position: relative;

    a {
      color: #000;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }

    }

    &:before {
      content: '';
      display: block;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background: #176d98;
      position: absolute;
      top: 11px;
      left: 10px;
    }

  }

}

aside.sidebar {

  @include more-1200 {
    @include shadow;
  }

}

.main-footer {

  @include more-768 {

    &-grid {
      position: relative;
      display: flex;

      .offer,
      .chief {
        width: 50%;
        margin: 0;
      }

      &:before {
        content: '';
        width: 50%;
        background: linear-gradient(to top, transparent, #f0f0f0 35px);
        position: absolute;
        left: 0;
        top: 0;
        height: 40px;
        z-index: 1;
      }

      &:after {
        content: '';
        width: 50%;
        background: linear-gradient(to bottom, transparent, #f0f0f0 50px);
        position: absolute;
        left: 0;
        bottom: 0;
        height: 60px;
        z-index: 1;
      }

    }

    &-shadow {
      position: relative;

      &:before {
        content: '';
        width: 100%;
        background: linear-gradient(to top, transparent, #f0f0f0 35px);
        position: absolute;
        left: 0;
        top: 0;
        height: 40px;
        z-index: 1;
      }

      &:after {
        content: '';
        width: 100%;
        background: linear-gradient(to bottom, transparent, #f0f0f0 50px);
        position: absolute;
        left: 0;
        bottom: 0;
        height: 60px;
        z-index: 1;
      }

    }

  }

  @include more-1200 {
    display: none;
  }

}

.is-select {
  width: 100%;
  list-style: none;
  background: linear-gradient(to bottom, #fff, #fbfbfb);
  line-height: 40px;
  font-size: 14px;
  color: #414649;
  padding: 0 44px 0 25px;
  border: 1px solid #cfd3d4;
  border-radius: 20px;
  position: relative;
  background: #f9fafa;

  &:after {
    content: '';
    display: block;
    width: 8px;
    height: 5px;
    background: url(../images/icon-arrow-small-orange.png);
    position: absolute;
    top: 18px;
    right: 18px;
    z-index: 1;
    transform-origin: center center;
    transition: .3s;
  }

  &-value {
    display: block;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &-menu {
    display: none;
    width: calc(100% + 19px);
    margin: 0;
    padding: 0 0 15px;
    list-style: none;
  }

  &-menu-item {
    padding: 5px 10px;
    border-bottom: 1px solid #cdcece;
    font-size: 14px;
    line-height: 1.1;
    font-weight: 700;

    &:last-of-type {
      border-bottom: none;
    }

    a {
      color: #000;

      &:hover {
        color: #f06021;
      }

    }

  }

  &.active {

    &:after {
      transform: rotate(-180deg);
    }

  }

}

.is-tooltip {
  z-index: 10;

  &-btn {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #176c97;
    font-size: 11px;
    line-height: 20px;
    color: #fff;
    text-align: center;
    cursor: pointer;

    &:hover {

      & + .is-tooltip-text {
        display: block;
      }

    }

  }

  &-text {
    display: none;
    width: 200px;
    padding: 15px;
    text-align: justify;
    border-radius: 10px;
    position: absolute;
    top: calc(100% + 10px);
    right: -10px;
    background: #444;
    color: #fff;
    font-size: 14px;
    line-height: 18px;

    &:after {
      content: '';
      display: block;
      width: 0;
      height: 0;
      border-bottom: 8px solid #444;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      position: absolute;
      right: 13px;
      bottom: 100%;
    }

  }

}

.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0 20px;

  &-numbers {
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;

    li {

      a {
        display: block;
        width: 17px;
        text-align: center;
        font-size: 15px;
        font-weight: 700;
        color: #000;

        &.active {
          width: 25px;
          height: 25px;
          border-radius: 50%;
          line-height: 25px;
          background: #094b6d;
          color: #fff;
          margin: 0 3px;
        }

      }

    }

  }

  &-control {

    &-text {
      display: none;

      @include more-768 {
        display: block;
        font-size: 15px;
        line-height: 25px;
        font-weight: 700;
        color: #000;
      }

    }

    &-prev {
      display: flex;
      align-items: center;
      cursor: pointer;

      &:hover {
        text-decoration: none;
      }

      &:before {
        content: '';
        display: block;
        width: 8px;
        height: 5px;
        background: url(../images/icon-arrow-small-orange.png);
        transform: rotate(90deg);
        margin: 0 5px 0 0;
      }

    }

    &-next {
      display: flex;
      align-items: center;
      cursor: pointer;

      &:hover {
        text-decoration: none;
      }

      &:after {
        content: '';
        display: block;
        width: 8px;
        height: 5px;
        background: url(../images/icon-arrow-small-orange.png);
        transform: rotate(-90deg);
        margin: 0 0 0 5px;
      }

    }

  }

  @include more-768 {
    margin: 15px 0 30px;
  }

}

.content-header {

  @include more-768 {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .store-title {
      margin: 0;
    }

    .is-select {
      margin: 0;
    }

  }

}
